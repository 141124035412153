import styled, { css } from "styled-components";

import Media from "./Media";

export const col4 = css`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
`;

export const col3 = css`
  flex: 0 0 25%;
  max-width: 25%;
`;

export const col10 = css`
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
`;

export const col2 = css`
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
`;

export const col6 = css`
  flex: 0 0 50%;
  max-width: 50%;
`;

export const tablePadding = css`
  padding: 0 15px;
`;

export const backgroundImgCover = css`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const CustomLink = styled.a`
  color: inherit;
`;

export const ResponsiveBlock = css`
  max-width: ${(props) => props.theme.widthByBreakPoint[1440]};

  ${Media.desktop`
    max-width: ${(props) => props.theme.widthByBreakPoint[768]};
    padding: 0 24px;
  `}

  ${Media.mobile`
    max-width: ${(props) => props.theme.widthByBreakPoint[645]};
    padding: 0 16px;
  `}
`;

import { useEffect, useState } from "react";

import { sizes } from "@/styles/website/Media";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState(null);

  const { mobile, tablet, desktop } = sizes;

  useEffect(() => {
    function handleResize() {
      if (document.body.offsetWidth > desktop) {
        setWindowSize("large-desktop");
        return;
      }

      if (document.body.offsetWidth > tablet) {
        setWindowSize("desktop");
        return;
      }

      if (
        document.body.offsetWidth <= tablet &&
        document.body.offsetWidth > mobile
      ) {
        setWindowSize("tablet");
        return;
      }

      if (document.body.offsetWidth <= mobile) {
        setWindowSize("mobile");
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

export default useWindowSize;

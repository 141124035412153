const settings = {
  API_DOMAIN: "https://apidev.elecle.bike",
  CDN_DOMAIN: "https://d3e0eik209ld4c.cloudfront.net/assets",
  DEEPLINK_URL: "https://pyces.test-app.link",
  PEOPLES_S3_DOMAIN:
    "https://nine2one.s3.ap-northeast-2.amazonaws.com/station/people/data.json"
};

if (process.env.NEXT_PUBLIC_MODE === "production") {
  settings.API_DOMAIN = "https://api.elecle.bike";
  settings.CDN_DOMAIN = "https://cdn.elecle.bike/assets";
  settings.DEEPLINK_URL = "https://matrix.elecle.bike";
}

export default settings;

export const IMP_CODE = "imp92659568";

export const ALLOWED_BIKE_TYPE = ["generic", "plus", "model3"];

export const STORE_MAIN_BANNER_SRC = {
  NEWBIE: "store_newbie_banner.png",
  NORMAL: "store_banner.png",
  WINTER21: "store_winter_21_banner.png",
  NEW: "store_top_banner.png"
};

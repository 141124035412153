import { useState, useEffect } from "react";

import Link from "next/link";
import styled from "styled-components";

import useWindowSize from "@/hooks/useWindowSize";
import { fontUtil } from "@/styles/website/font";
import Media from "@/styles/website/Media";
import { CustomLink, ResponsiveBlock } from "@/styles/website/mixin";
import settings from "@/configs/index";

interface Props {
  whiteGnb: boolean;
}

const getLogoUrl = (whiteGnb: boolean) => {
  return (
    settings.CDN_DOMAIN +
    (whiteGnb ? "/website/logo_blue.svg" : "/website/logo-white.svg")
  );
};

const useScroll = () => {
  const [whiteGnb, setWhiteGnb] = useState(false);
  const onScroll = () => {
    if (typeof window !== "undefined") {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 76) {
        setWhiteGnb(true);
      } else {
        setWhiteGnb(false);
      }
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", onScroll); // scorll할 때 onScroll 이벤트 핸들러 지정
    }
    return () => window.removeEventListener("scroll", onScroll); // clean up
  }, []);
  return { whiteGnb, setWhiteGnb };
};

const menus = [
  { text: "홈", link: "/", mobileOnly: true },
  { text: "서비스소개", link: "/service" },
  {
    text: "회사소개",
    link: "/company"
  },
  {
    text: "사람들",
    link: "/people"
  },
  {
    text: "블로그",
    link: "/blog"
  },
  {
    text: "가맹사업자 모집",
    link: "/partner"
  }
];

const DesktopGnbComponent = () => {
  const { whiteGnb } = useScroll();

  return (
    <Gnb whiteGnb={whiteGnb}>
      <FixedWidthBar>
        <Link href="/" passHref>
          <CustomLink>
            <img alt="elecle_logo" src={getLogoUrl(whiteGnb)} />
          </CustomLink>
        </Link>
        <nav>
          <GnbMenu>
            {menus.map(
              (menu) =>
                !menu.mobileOnly && (
                  <Menu key={menu.text}>
                    <Link href={menu.link} passHref>
                      <MenuLink whiteGnb={whiteGnb}>{menu.text}</MenuLink>
                    </Link>
                  </Menu>
                )
            )}
          </GnbMenu>
        </nav>
      </FixedWidthBar>
    </Gnb>
  );
};

const MobileGnbComponent = () => {
  const { whiteGnb, setWhiteGnb } = useScroll();
  const [showNavigation, setShowNavigation] = useState(false);

  const handleClick = () => {
    setShowNavigation(!showNavigation);
  };

  useEffect(() => {
    if (showNavigation) {
      document.body.style.overflow = "hidden";
      setWhiteGnb(true);
    } else {
      document.body.style.overflow = "unset";
      const scrollTop = window.pageYOffset;
      if (!(scrollTop > 76)) {
        setWhiteGnb(false);
      }
    }
  }, [showNavigation]);

  useEffect(() => {
    return () => {
      setShowNavigation(false);
    };
  }, []);

  const returnTogglerImage = () => {
    if (showNavigation) return "/static/website/layout/close.png";
    if (whiteGnb) return "/static/website/layout/toggler_dark.png";
    return "/static/website/layout/toggler.png";
  };

  return (
    <>
      <Gnb whiteGnb={whiteGnb}>
        <FixedWidthBar>
          <Link href="/" passHref>
            <CustomLink>
              <NavLogo alt="elecle_logo" src={getLogoUrl(whiteGnb)} />
            </CustomLink>
          </Link>
          <button type="button" onClick={handleClick}>
            <TogglerImage src={returnTogglerImage()} />
          </button>
          {showNavigation && (
            <MobileMenuWrapper>
              {menus.map((menu) => (
                <MobileMenu key={menu.text}>
                  <Link href={menu.link} passHref>
                    <CustomLink>{menu.text}</CustomLink>
                  </Link>
                </MobileMenu>
              ))}
            </MobileMenuWrapper>
          )}
        </FixedWidthBar>
      </Gnb>
      {showNavigation && <Overlay />}
    </>
  );
};

const GnbComponent = () => {
  const windowSize = useWindowSize();
  const isLargeDesktop = windowSize === "large-desktop";
  const isDesktop = windowSize === "desktop";

  return isDesktop || isLargeDesktop ? (
    <DesktopGnbComponent />
  ) : (
    <MobileGnbComponent />
  );
};

export default GnbComponent;

type GnbProps = {
  children: React.ReactNode;
} & Props;

type ImgProps = {
  alt?: string;
  src?: string;
};

type MobileMenuProps = {
  children: React.ReactNode;
};

type MenuLinkProps = {
  whiteGnb: boolean;
  children: React.ReactNode;
};

type MenuProps = {
  children: React.ReactNode;
};

export const Gnb = styled.header<GnbProps>`
  height: 80px;
  width: 100%;
  background: ${(props) => (props.whiteGnb ? "white" : "none")};
  position: fixed;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;

  ${Media.tablet`
    height: auto;
    padding: 14px 0;
  `}
`;
export const FixedWidthBar = styled.div`
  ${ResponsiveBlock}
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  align-self: stretch;

  ${Media.tablet`
    flex-wrap: wrap;
  `}

  button {
    border: none;
    outline: none;
    background-color: transparent;
    height: 32px;
  }
`;

const NavLogo = styled.img<ImgProps>`
  ${Media.tablet`
    width: 100px;
  `}
`;

export const GnbMenu = styled.ol`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Menu = styled.li<MenuProps>`
  text-align: center;
  ${fontUtil("gnbMenuTitle")}
  &:not(:last-of-type) {
    margin-right: 62px;

    ${Media.desktop`
    margin-right: 20px;
  `}
  }
`;

const TogglerImage = styled.img<ImgProps>`
  width: 32px;
  height: 32px;
`;

const MobileMenuWrapper = styled.ol`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const MobileMenu = styled.li<MobileMenuProps>`
  text-align: center;
  padding: 28px 0;
  ${fontUtil("Gnb")}
  color:${(props) => props.theme.colors.gray001};

  ${Media.mobile`
    padding: 13px 0;
  `}
`;

const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000000;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: 20;
`;

const MenuLink = styled.a<MenuLinkProps>`
  color: ${(props) =>
    props.whiteGnb ? props.theme.colors.black : props.theme.colors.white};
`;

import { useEffect } from "react";

import AOS from "aos";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import FooterComponent from "../website/FooterComponent";
import GnbComponent from "../website/GnbComponent";

import theme from "@/styles/website/stylesheet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

const GlobalStyle = createGlobalStyle`
* {
  font-family: "Spoqa Han Sans Neo";
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

[data-aos="custom-fade-up"] {
  transform: translate3d(0, 20px, 0);
  opacity: 0;
  transition-property: transform, opacity;
}

[data-aos="custom-fade-up"].aos-animate {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
`;

export default function WebsiteLayout({
  children
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <GnbComponent />
        <main>{children}</main>
        <FooterComponent />
      </ThemeProvider>
    </>
  );
}

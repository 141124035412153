import Link from "next/link";
import styled from "styled-components";

import useWindowSize from "@/hooks/useWindowSize";
import { fontUtil } from "@/styles/website/font";
import Media from "@/styles/website/Media";
import { CustomLink, ResponsiveBlock } from "@/styles/website/mixin";
import settings from "@/configs/index";

const FooterComponent = () => {
  const windowSize = useWindowSize();
  const isLargeDesktop = windowSize === "large-desktop";
  const isDesktop = windowSize === "desktop";
  const isMobile = windowSize === "mobile";

  const menus = [
    { text: "서비스소개", link: "/service" },
    {
      text: "회사소개",
      link: "/company"
    },
    {
      text: "사람들",
      link: "/people"
    },
    {
      text: "블로그",
      link: "/blog"
    }
  ];
  return (
    <Footer>
      {!isMobile && (
        <FooterMenuFrame>
          <Menus>
            {menus.map((menu) => (
              <Menu key={menu.text}>
                <Link href={menu.link} passHref>
                  <CustomLink>{menu.text}</CustomLink>
                </Link>
              </Menu>
            ))}
          </Menus>
        </FooterMenuFrame>
      )}
      <Divider />
      <FooterMainFrame>
        <FooterMain>
          <FooterTopWrapper>
            <FooterTop>
              <SvgLogo
                alt=""
                src={`${settings.CDN_DOMAIN}/website/logo_blue.svg`}
              />
              <FooterLink>
                <Link href="/legal/privacy-policy" passHref>
                  <CustomLink>
                    <Text>개인(위치)정보 처리방침</Text>
                  </CustomLink>
                </Link>
                <Text>|</Text>
                <Link href="/legal/terms-of-service" passHref>
                  <CustomLink>
                    <Text>이용약관</Text>
                  </CustomLink>
                </Link>
                <Text>|</Text>
                <Link href="/legal/location-based" passHref>
                  <CustomLink>
                    <Text>위치정보이용약관</Text>
                  </CustomLink>
                </Link>
              </FooterLink>
            </FooterTop>
            {isMobile && (
              <Sns>
                <Link href="https://www.facebook.com/elecle" passHref>
                  <CustomLink>
                    <Facebook alt="" src="/static/icon/facebook.png" />
                  </CustomLink>
                </Link>
                <Link href="https://www.instagram.com/elecle_bike/" passHref>
                  <CustomLink>
                    <Instagram alt="" src="/static/icon/instagram.png" />
                  </CustomLink>
                </Link>
              </Sns>
            )}
          </FooterTopWrapper>
          <Footer>
            <FooterContent>
              (주)나인투원 사업자정보
              <br />
              주식회사 나인투원 / Nine2one Inc | 대표자 배지훈 |
              개인정보관리책임자 최정완 | 사업자등록번호 654-81-01178 |
              통신판매업신고 제 2023-서울성동-2045호
              {(isDesktop || isLargeDesktop) && <br />} 주소 : 서울 성동구
              왕십리로 83-21 디타워서울포레스트 3층
            </FooterContent>
            <FooterBottom>
              <Contacts>
                <Contact>
                  <Title>이메일 문의</Title>
                  <Content>contact&#64;nine2one.io</Content>
                </Contact>
                <Contact>
                  <Title>전화 문의</Title>
                  <Content>1800-1192</Content>
                </Contact>
              </Contacts>

              {!isMobile && (
                <Sns>
                  <Link href="https://www.facebook.com/elecle" passHref>
                    <CustomLink>
                      <Facebook alt="" src="/static/icon/facebook.png" />
                    </CustomLink>
                  </Link>
                  <Link href="https://www.instagram.com/elecle_bike/" passHref>
                    <CustomLink>
                      <Instagram alt="" src="/static/icon/instagram.png" />
                    </CustomLink>
                  </Link>
                </Sns>
              )}
            </FooterBottom>
          </Footer>
        </FooterMain>
      </FooterMainFrame>
    </Footer>
  );
};

export default FooterComponent;

type MenuProps = {
  children: React.ReactNode;
};

type ImgProps = {
  alt?: string;
  src: string;
};

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const FooterMenuFrame = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray001};
  padding: 35px 0 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Media.tablet`
    align-items: unset;
  `}
`;
export const Menus = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  ${ResponsiveBlock}

  ${Media.tablet`
    padding: 0 24px;
  `}

  ${Media.mobile`
    padding: 0 16px;
  `}
`;
export const Menu = styled.p<MenuProps>`
  color: ${(props) => props.theme.colors.white};
  ${fontUtil("FooterTitleBold")}
  &:not(:last-of-type) {
    margin-right: 35px;
  }
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.gray002};
`;
export const FooterMainFrame = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray001};
  padding: 110px 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Media.tablet`
    padding: 70px 0 92px;
  `}

  ${Media.mobile`
    padding: 50px 0 92px;
  `}
`;
export const FooterMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${ResponsiveBlock}
`;

const FooterTopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
`;

export const FooterTop = styled.div`
  display: flex;
  align-items: flex-end;
  /* justify-content: center; */
`;
export const SvgLogo = styled.img<ImgProps>`
  margin-right: 14px;

  ${Media.tablet`
    width: 80px;
  `}
`;
export const FooterLink = styled.div`
  padding: 0 0 0 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  justify-content: start;
  height: 14px;
`;
export const RelativeWrapperOne = styled.div`
  position: relative;
  &:not(:last-of-type) {
    margin-right: 5px;
  }
`;
export const VerticalDivider = styled.img`
  content: url("/static/icon/divider.svg");
  height: 14px;
`;
export const Text = styled.p`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  position: relative;
  margin-right: 5px;
  ${fontUtil("FooterSubtitle")}
`;
export const FooterContent = styled.p`
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 10px;
  ${fontUtil("FooterDescription")}

  ${Media.tablet`
    max-width: unset;
    margin-bottom: 20px;
  `}
`;
export const FooterBottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Contacts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Contact = styled.div`
  display: flex;
  align-items: baseline;

  &:not(:last-of-type) {
    margin-right: 56px;
  }
`;
export const Title = styled.p`
  color: ${(props) => props.theme.colors.white};
  margin-right: 10px;
  ${fontUtil("FooterTitleBold")}
`;
export const Content = styled.p`
  color: ${(props) => props.theme.colors.white};
  ${fontUtil("FooterTitle")}
`;
export const Facebook = styled.img<ImgProps>`
  width: 17px;
  margin-right: 28px;

  ${Media.tablet`
    width: 11px;
  `}

  ${Media.mobile`
    width: 8px;
    margin-right: 15px;
  `}
`;

const Instagram = styled(Facebook)<ImgProps>`
  width: 33px;
  margin-right: 0;

  ${Media.tablet`
    width: 24px;
  `}

  ${Media.mobile`
    width: 18px;
  `}
`;

export const Sns = styled.div`
  display: flex;
  align-items: center;
`;

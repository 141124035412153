/* This is the project stylesheet */

export default {
  colors: {
    black: "rgba(0,0,0,1)",
    elecleRed500: "rgba(255,105,72,1)",
    elecleBlue500: "rgba(27, 122, 255, 1)",
    indication500: "rgba(21, 143, 255, 1)",
    gray001: "rgba(51,51,51,1)",
    gray002: "rgba(102,102,102,1)",
    gray003: "rgba(150,151,153,1)",
    gray004: "rgba(212,213,217,1)",
    gray006: "rgba(247,247,247,1)",
    transparentBlack: "rgba(0,0,0,0.1)",
    white: "rgba(255,255,255,1)",
    red: "rgba(255,69,44,1)"
  },
  fonts: {
    heroDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "60px",
      weight: "400",
      lineHeight: "90px"
    },
    heroTablet: {
      family: "Spoqa Han Sans Neo",
      size: "45px",
      weight: "400",
      lineHeight: "67.5px"
    },
    heroMobile: {
      family: "Spoqa Han Sans Neo",
      size: "30px",
      weight: "400",
      lineHeight: "45px"
    },
    h1Desktop: {
      family: "Spoqa Han Sans Neo",
      size: "38px",
      weight: "400",
      lineHeight: "57px"
    },
    h1Tablet: {
      family: "Spoqa Han Sans Neo",
      size: "30px",
      weight: "400",
      lineHeight: "45px"
    },
    h1Mobile: {
      family: "Spoqa Han Sans Neo",
      size: "22px",
      weight: "400",
      lineHeight: "33px"
    },
    h2Desktop: {
      family: "Spoqa Han Sans Neo",
      size: "32px",
      weight: "400",
      lineHeight: "48px"
    },
    h2Tablet: {
      family: "Spoqa Han Sans Neo",
      size: "28px",
      weight: "400",
      lineHeight: "42px"
    },
    h2Mobile: {
      family: "Spoqa Han Sans Neo",
      size: "20px",
      weight: "400",
      lineHeight: "30px"
    },
    h3Desktop: {
      family: "Spoqa Han Sans Neo",
      size: "32px",
      weight: "400",
      lineHeight: "48px"
    },
    h3Tablet: {
      family: "Spoqa Han Sans Neo",
      size: "22px",
      weight: "400",
      lineHeight: "33px"
    },
    h3Mobile: {
      family: "Spoqa Han Sans Neo",
      size: "16px",
      weight: "400",
      lineHeight: "24px"
    },
    title1Desktop: {
      family: "Spoqa Han Sans Neo",
      size: "30px",
      weight: "700",
      lineHeight: "45px"
    },
    title1Tablet: {
      family: "Spoqa Han Sans Neo",
      size: "23px",
      weight: "700",
      lineHeight: "34.5px"
    },
    title1Mobile: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "700",
      lineHeight: "27px"
    },
    subtitle1BoldDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "23px",
      weight: "700",
      lineHeight: "34.5px"
    },
    subtitle1BoldTablet: {
      family: "Spoqa Han Sans Neo",
      size: "20px",
      weight: "700",
      lineHeight: "29px"
    },
    subtitle1BoldMobile: {
      family: "Spoqa Han Sans Neo",
      size: "16px",
      weight: "700",
      lineHeight: "27px"
    },
    subtitle1Desktop: {
      family: "Spoqa Han Sans Neo",
      size: "23px",
      weight: "400",
      lineHeight: "34.5px"
    },
    subtitle1Tablet: {
      family: "Spoqa Han Sans Neo",
      size: "20px",
      weight: "400",
      lineHeight: "29px"
    },
    subtitle1Mobile: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "400",
      lineHeight: "27px"
    },
    subtitle2BoldDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "22px",
      weight: "700",
      lineHeight: "33px"
    },
    subtitle2BoldTablet: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "700",
      lineHeight: "27px"
    },
    subtitle2BoldMobile: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "700",
      lineHeight: "21px"
    },
    subtitle2Desktop: {
      family: "Spoqa Han Sans Neo",
      size: "22px",
      weight: "400",
      lineHeight: "33px"
    },
    subtitle2Tablet: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "400",
      lineHeight: "27px"
    },
    subtitle2Mobile: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "400",
      lineHeight: "21px"
    },
    buttonDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "700",
      lineHeight: "27px"
    },
    buttonTablet: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "700",
      lineHeight: "27px"
    },
    buttonMobile: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "700",
      lineHeight: "21px"
    },
    p1Desktop: {
      family: "Spoqa Han Sans Neo",
      size: "20px",
      weight: "400",
      lineHeight: "34.5px"
    },
    p1Tablet: {
      family: "Spoqa Han Sans Neo",
      size: "16px",
      weight: "400",
      lineHeight: "27px"
    },
    p1Mobile: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "400",
      lineHeight: "22.5px"
    },
    p2Desktop: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "400",
      lineHeight: "27px"
    },
    p2Tablet: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "400",
      lineHeight: "21px"
    },
    p2Mobile: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "400",
      lineHeight: "21px"
    },
    p2BoldDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "700",
      lineHeight: "27px"
    },
    p2BoldTablet: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "700",
      lineHeight: "21px"
    },
    p2BoldMobile: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "700",
      lineHeight: "21px"
    },
    GnbDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "700",
      lineHeight: "21px"
    },
    GnbTablet: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "400",
      lineHeight: "21px"
    },
    GnbMobile: {
      family: "Spoqa Han Sans Neo",
      size: "16px",
      weight: "400",
      lineHeight: "21px"
    },
    FooterTitleDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "400",
      lineHeight: "21px"
    },
    FooterTitleTablet: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "400",
      lineHeight: "26px"
    },
    FooterTitleMobile: {
      family: "Spoqa Han Sans Neo",
      size: "11px",
      weight: "400",
      lineHeight: "13px"
    },
    FooterTitleBoldDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "700",
      lineHeight: "21px"
    },
    FooterTitleBoldTablet: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "700",
      lineHeight: "26px"
    },
    FooterTitleBoldMobile: {
      family: "Spoqa Han Sans Neo",
      size: "11px",
      weight: "700",
      lineHeight: "13px"
    },
    FooterSubtitleDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "14px",
      weight: "700",
      lineHeight: "25px"
    },
    FooterSubtitleTablet: {
      family: "Spoqa Han Sans Neo",
      size: "11px",
      weight: "700",
      lineHeight: "13px"
    },
    FooterSubtitleMobile: {
      family: "Spoqa Han Sans Neo",
      size: "11px",
      weight: "700",
      lineHeight: "13px"
    },
    FooterDescriptionDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "12px",
      weight: "400",
      lineHeight: "18px"
    },
    FooterDescriptionTablet: {
      family: "Spoqa Han Sans Neo",
      size: "12px",
      weight: "400",
      lineHeight: "18px"
    },
    FooterDescriptionMobile: {
      family: "Spoqa Han Sans Neo",
      size: "9px",
      weight: "400",
      lineHeight: "13px"
    },
    gnbMenuTitleDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "700",
      lineHeight: "21px"
    },
    gnbMenuTitleTablet: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "400",
      lineHeight: "21px"
    },
    gnbMenuTitleMobile: {
      family: "Spoqa Han Sans Neo",
      size: "16px",
      weight: "400",
      lineHeight: "21px"
    },
    regionTitleDesktop: {
      family: "Spoqa Han Sans Neo",
      size: "30px",
      weight: "700"
    },
    regionTitleTablet: {
      family: "Spoqa Han Sans Neo",
      size: "23px",
      weight: "700"
    },
    regionTitleMobile: {
      family: "Spoqa Han Sans Neo",
      size: "18px",
      weight: "700"
    }
  },
  widthByBreakPoint: {
    1440: "1140px",
    768: "768px",
    645: "540px"
  }
};
